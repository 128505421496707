<template>
  <div class="about">
    <Slide>
      <a href="">Početna</a>
      <a href="">O Nama</a>
      <a href="">Nastavni Plan I Program</a>
      <a href="">Profesori I Asistenti</a>
    </Slide>
  </div>
</template>

<script>
import { Slide } from "vue3-burger-menu";

export default {
  components: {
    Slide,
  },
};
</script>

