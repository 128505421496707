<template>
  <div class="header-wrapper">
    <div class="holder">
        <div class="contact-info">
          <div class="contact-info-item">
              <a href="#contact">
                <img class="email-logo" src="@/assets/email.svg" alt="email-logo">
                <p>upis@fimit.edu.rs</p>
              </a>
              
          </div>
          <div class="contact-info-item">
            <a href="https://www.google.com/maps/place/%D0%91%D1%83%D0%BB%D0%B5%D0%B2%D0%B0%D1%80+%D0%B2%D0%BE%D1%98%D0%B2%D0%BE%D0%B4%D0%B5+%D0%9C%D0%B8%D1%88%D0%B8%D1%9B%D0%B0+43,+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4,+%D0%A1%D1%80%D0%B1%D0%B8%D1%98%D0%B0/@44.7947072,20.4363137,17z/data=!4m2!3m1!1s0x475a6fe22b1459ab:0xd099c42ce3b5c928" target="blank">
            <img class="location-logo" src="@/assets/location.svg" alt="location-logo">
            <p>Bulevar vojvode Mišića 43, Beograd</p>
            </a>   
          </div>
          <div class="contact-info-item">
            <img class="phone-logo" src="@/assets/phone.svg" alt="phone-logo">
            <p>011/41-40-420</p>
            </div>
        </div>
        <div class="social-networks">
            <a href="https://www.instagram.com/studiraj.it/" target="_blank"><span class="social-networks-item"><img src="@/assets/instagram.svg" alt=""></span></a>
            <a href="https://www.youtube.com/channel/UCaxeGrURyUjNHYjH4dbtjMA" target="_blank"><span class="social-networks-item"><img src="@/assets/youtube.svg" alt=""></span></a>
        </div>
    </div>  
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.header-wrapper{
    max-width: 1920px;
    margin: 0 auto;
    max-height: 39px;
    background-color: #175FD9;
    color: #FFFFFF;
    padding: 0px calc(230px - 10vw);
    position: sticky;
    top: 0;
    z-index: 100;
    user-select: none; 
   -webkit-user-select: none;
   -khtml-user-select: none; 
   -moz-user-select: none;
   -ms-user-select: none; 
}
.holder{
    max-width: 1455px;
    max-height: 39px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
}
.contact-info {
    display:flex;
    justify-content: space-around;
    align-items: center;
}
.contact-info-item{
    display: flex;
    justify-content: space-around;
    margin-right: 17px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
}
.contact-info-item a{
    display: flex;
    justify-content: space-around;
    margin-right: 17px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
}
.contact-info-item img{
    margin-right: 9.25px;
}
.social-networks{
    width: 55px;
    display: flex;
    justify-content: space-between;
}

.social-networks-item img{
    width:15px ;
    height: 15px;
    cursor: pointer;
}
.social-networks-item:hover{
    opacity: 0.5;
}

.email-logo{
    width: 19px;
}
.location-logo{
    width: 12px;
}
.phone-logo{
    width: 15px; 
}
#phone-tel{
    display: none;
}
/* @media only screen and (max-width: 1536px){
    .holder{
        padding: 0 100px;
    }
} */
 @media only screen and (max-width: 1366px){
     .contact-info-item{
         font-size: 13px;
     }
}
@media only screen and (max-width: 1260px){
    .header-wrapper{
        display: none;
    }
}
@media only screen and (max-width: 1060px){
    .header-wrapper{
        display: none;
    }
}
@media only screen and (max-width: 486px){
    .header-wrapper{
        display: none;
    }
}

</style>