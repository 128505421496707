<template>
  <div class="navigation-wrapper">
      <div class="holder">
        <div class="logos">
            <a href="https://fim.edu.rs/en/homepage/" target="_blank">
                <span class="logos-item fim-logo"><img src="@/assets/fim.svg" alt=""></span>
            </a>
            <p>FAKULTET ZA INŽENJERSKI MENADŽMENT</p>
            <a href="https://fimit.rs/" target="_blank">
                <span class="logos-item fim-logo2"><img src="@/assets/itSmer.svg" alt=""></span>
            </a>
            <p>INFORMACIONO KOMUNIKACIONE TEHNOLOGIJE</p>
        </div>
        <!-- <div class="nav" >
            <ul>
                <li><a href="#navigation">Početna</a></li>
                <li><a href="#aboutUs">O nama</a></li>
                <li><a href="#schoolPlan">Nastavni plan i program</a></li>
                <li><a href="#profesors" id="list-item">Profesori i asistenti</a></li>
            </ul> 
            <a href="#contact"><button>Prijavi se besplatno</button></a>
        </div> -->
        <div class="burger-menu" @click="showBurgerMenu" v-if="burgerClose">
            <div class="line"> </div>
            <div class="line"> </div>
            <div class="line"> </div>
        </div>
        <div class="x" v-else @click="hideMenu">
           <img src="@/assets/x.svg" alt="x">
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapState} from 'vuex';
export default {
     computed: {
        ...mapState(['burgerClose']),
  },
    data(){
        return{
            
        }
    },
    methods:{
         ...mapActions(['burgerCloseChange']),
        showBurgerMenu(){
            this.burgerCloseChange(false)
        },
        hideMenu() {
            this.burgerCloseChange(true)
        },  
    }
}
</script>

<style scoped>
.logos{
    display: flex;
    align-items: center;
}
.logos p{
    font-family: Montserrat;
    font-size: 13px;
    color: #1E458C;
    font-weight: 700;
    margin-right: 20px;
    margin-left: 5px;
    line-height: 18px;
    text-align: left;
}
 .burger-menu:hover {
  opacity: 0.5;
  cursor: pointer;
}
.x:hover{
    opacity: 0.5;
    cursor: pointer;
}
.navigation-wrapper{
    max-width: 1920px;
    margin: 0 auto;
    background-color: #FFFFFF;
    color: #16224D;
    padding: 10px calc(230px - 10vw);
    position: sticky;
    top: 25px;
    z-index: 100;
    box-shadow: 0px 10px 10px -16px #111;
    user-select: none; 
   -webkit-user-select: none;
   -khtml-user-select: none; 
   -moz-user-select: none;
   -ms-user-select: none; 
}
.holder{
    max-width: 1455px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin: 0 auto;
}
.logos {
    width: 236px;
    display:flex;
    justify-content: space-between;
    /* background-color: red; */
}

.fim-logo img{
    width: 118px;
    height: 75px;
    /* background-color: cornflowerblue; */
}
.fim-logo2 img{
    width: 71px;
    height: 75px;
    margin-right: 10px;
    /* background-color: darkgoldenrod; */
}
.nav{
    max-width: 655px;
    max-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: blue; */
    /* text-align: center; */
    
}
.nav ul{
    width: 439px;
    /* background-color: red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type:none;
    
}
.nav ul li{
    word-wrap:break-word;
    max-width:100px;
    text-align: center;
    
}
.nav ul li a{
    text-decoration: none;
    color: #16224D;
    font-family: Open Sans;
    font-size: 15px;
}
.nav ul li a:hover{
    opacity: 0.5;
}
.nav button{
    width: 166px;
    height: 50px;
    background-color: #175FD9;
    border-radius: 5px;
    color: #FFFFFF;
    border-style:none;
    font-family: Open Sans;
    font-size: 15px;
    cursor: pointer;
    margin-left: 50px;
}
.nav button:hover{
    opacity: 0.5;
}
.logos-item:hover{
    opacity: 0.5;
}
.burger-menu{
    display: none;
}
#list-item{
    max-width: 69px;
}
 @media only screen and (max-width: 1366px){
    .logos{
        width: 200px;
    }
    .fim-logo img{
        width: 112px;
        height: 67px;
        cursor: pointer;
    }
    .fim-logo2 img{
        width: 65px;
        height: 67px;
        cursor: pointer;
    }
    .nav ul{
        width: 400px;        
    }
    .nav button{
        width: 150px;
        height: 50px;
    }
}
@media only screen and (max-width: 1260px){
    .navigation-wrapper{
        top: -1px;
    }
    .nav{
        display: none;
    }
    .burger-menu{
        display: none;
    }
    .line{
        width: 35px;
        height: 4px;
        background-color: #0E2A59;
        margin-bottom: 6px;
    }
}
@media only screen and (max-width: 1060px){
    .navigation-wrapper{
        padding: 10px 60px;
        top: 0;
    }
    .nav{
        display: none;
    }
    .logos{
        width: 172px;
    }
    .fim-logo img{
        width: 100px;
        height: 58px;
    }
    .fim-logo2 img{
        width: 53px;
        height: 58px;
    }
    .burger-menu{
        display: none;
    }
    .line{
        width: 35px;
        height: 4px;
        background-color: #0E2A59;
        margin-bottom: 6px;
    }
}
@media only screen and (max-width: 590px){
    .fim-logo img{
        width: 63px;
        height: 44px;
    }
    .fim-logo2 img{
        width: 42px;
        height: 44px;
    }
    .logos p{
        font-size: 10px;
        line-height: 14px;
    }
}
@media only screen and (max-width: 486px){
    .navigation-wrapper{
        padding: 20px 30px;
        margin: 0 auto;
    }
    .nav{
        display: none;
    }
    .logos{
        width: 108px;
    }
    .fim-logo img{
        width: 53px;
        height: 34px;
    }
    .fim-logo2 img{
        width: 32px;
        height: 34px;
    }
    .burger-menu{
        display: none;
    }
    .line{
        width: 22px;
        height: 3px;
        background-color: #0E2A59;
        margin-bottom: 4px;
    }
}
@media only screen and (max-width: 380px){
    .fim-logo img{
        width: 53px;
        height: 34px;
    }
    .fim-logo2 img{
        width: 32px;
        height: 34px;
        margin-right: 3px;
    }
    .logos p{
        font-size: 9px;
        line-height: 12px;
        margin-right: 5px;
    }
}
@media only screen and (max-width: 340px){
    .logos p{
        font-size: 8px;
        margin-right: 3px;
    }
}

</style>