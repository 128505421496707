import { createApp } from 'vue'
import App from './App.vue'
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App).use(store).use(router).use(moshaToast).use(VueReCaptcha,{siteKey:'6LdR-j4eAAAAAK7zAL7F6ZK2KJZjJXkJpe8RSI_w'}).mount('#app');
// App.use(VueReCaptcha, { siteKey:'6LdR-j4eAAAAAK7zAL7F6ZK2KJZjJXkJpe8RSI_w'});


