<template>
    <div class="wrapper" >
        <div class="menu">
            <ul>
                <li @click="hideMenu"><a href="#navigation">Početna</a></li>
                <li @click="hideMenu"><a href="#aboutUs">O nama</a></li>
                <li @click="hideMenu"><a href="#schoolPlan">Nastavni plan i program</a></li>
                <li @click="hideMenu"><a href="#profesors">Profesori i asistenti</a></li>
                <li @click="hideMenu"><a href="#contact"><button>Prijavi se besplatno</button></a></li>
            </ul>
        </div>
        <div class="footer">
            <div class="info">
                <div class="info-item-logo">
                    <img src="@/assets/email.svg" alt="email-logo" class="email">
                    <img src="@/assets/location.svg" alt="location-logo" class="location">
                    <img src="@/assets/phone.svg" alt="email-logo" class="phone">
                </div>
                <div class="info-item">
                    <p>upis@fimit.edu.rs</p>
                    <a href="https://www.google.com/maps/place/%D0%91%D1%83%D0%BB%D0%B5%D0%B2%D0%B0%D1%80+%D0%B2%D0%BE%D1%98%D0%B2%D0%BE%D0%B4%D0%B5+%D0%9C%D0%B8%D1%88%D0%B8%D1%9B%D0%B0+43,+%D0%91%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D0%B4,+%D0%A1%D1%80%D0%B1%D0%B8%D1%98%D0%B0/@44.7947072,20.4363137,17z/data=!4m2!3m1!1s0x475a6fe22b1459ab:0xd099c42ce3b5c928" target="blank">
                        <p>Bulevar vojvode Mišića 43, Beograd</p>
                    </a>
                    <p id="desk-phone">011/41-40-420</p>
                    <a href="tel:+381114140420" id="tel-phone"><p>011/41-40-420</p></a>
                </div>
            </div>
            <div class="social">
                <div class="insta">
                    <a href="http://www.instagram.com/studiraj.it" target="_blank"><img src="@/assets/instagram.svg" alt="instagram"></a>
                </div>
                <div>
                    <a href="https://www.youtube.com/watch?v=J63zEUF5YkU" target="_blank"><img src="@/assets/youtube.svg" alt="youtube"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapState} from 'vuex';
export default {
     computed: {
        ...mapState(['burgerClose']),
  },
  methods:{
         ...mapActions(['burgerCloseChange']),
        showBurgerMenu(){
            this.burgerCloseChange(false)
        },
        hideMenu() {
            this.burgerCloseChange(true)
        },
    }
}
</script>

<style scoped>
.wrapper{
    display: flex;
    flex-direction: column;
    height: 100vh;
    user-select: none; 
   -webkit-user-select: none;
   -khtml-user-select: none; 
   -moz-user-select: none;
   -ms-user-select: none; 
}
.menu{
    height: 287px;
    text-align: left;
    padding: 24px 30px;
    font-family: Open Sans;
    font-size: 15px;
    position: sticky;
    top: 82px;

}
.footer{
    height: 200px;
    background-color: #175FD9;
    padding: 19px 30px;   
    color: #FFFFFF;
    font-family: Open Sans;
    font-size: 15px; 
    font-weight: 400;
    position: sticky;
    top: 369px;
}
.menu ul{
    list-style-type: none;
}
.menu ul li{
    margin-bottom: 23px;
}
.menu ul li a{
    text-decoration: none;
    color: #16224D;
}
.menu ul li a:hover{
    opacity: 0.5;
}
button{
    width: 166px;
    height: 50px;
    color: #FFFFFF;
    background-color: #175FD9;
    font-family: Open Sans;
    font-size: 15px;
    border: none;
    border-radius: 3px;
    font-weight: 400;
    line-height: 25px;
    font-style: normal;
}
button:hover{
    opacity: 0.5;
}
.social{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-top: 25px;
}
.info{
    display: flex;
    flex-direction: row;
    line-height: 166%;
}
.info-item-logo{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    
    
}
.info-item{
    text-align: left;
    margin-left: 20px; 
}
.info-item a{
    text-decoration: none;
    color: #FFFFFF;
}
.insta{
    margin-right: 53px;
}
.social img:hover{
 opacity: 0.5;
}
.social div img{
    width: 35px;
    height: 35px;
}
.email{
width:19px;
height: 15px;    
}
.location{
width:12px;
height:15px;
}
.phone{
width:15px;
height: 15px;
}
.info-item-logo img{
    margin-bottom: 10px;
}
.info-item p{
    margin-bottom: 10px;
}
#tel-phone{
    display: none;
}
@media only screen and (max-width: 1260px){
    .menu{
        padding: 24px calc(230px - 10vw);
    }
    .footer{
        padding: 17px calc(230px - 10vw);
    }
}
@media only screen and (max-width: 1060px){
    .menu{
        padding: 24px 60px;
    }
    .footer{
        padding: 17px 60px;
    }
}
@media only screen and (max-width: 486px){
    .menu{
        padding: 24px 30px;
        top: 78px;
    }
    .footer{
        padding: 17px 30px;
        top: 365px;
    }
    #tel-phone{
        display: inline;
        text-decoration: none;
        color: #FFFFFF;
    }
    #tel-phone p{
        color: #FFFFFF;
    }
    #desk-phone{
        display: none;
    }
    
}
@media only screen and (max-width: 345px){
    .footer{
        height: 240px;
    }
}
</style>