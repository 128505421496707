<template>
  <div class="fimit-slider-wrapper">
      <div class="slider">
          <img src="@/assets/fimit-slider2.png" alt="" class="standard-img">
          <img src="@/assets/mobile-construction.png" alt="" class="mobile-show">
          <div class="insta-and-youtube">
              <div class="insta" @click="redirect('https://www.instagram.com/studiraj.it')"></div>
              <div class="youtube" @click="redirect('https://www.youtube.com/channel/UCaxeGrURyUjNHYjH4dbtjMA')"></div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
methods:{
    redirect(url){
        window.open(url,"_blank")
    }
}
}
</script>

<style scoped>
.standard-img{
    display: block;
}
.mobile-show{
    display: none;
}
.fimit-slider-wrapper{
    margin: 0 auto;
    width: 100%;
}
.slider{
    width: 100%;
    position:relative;
}
.slider img{
    width: 100%;
    
}
.insta-and-youtube{
    display: flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
    position: absolute;
    top:0;
}
.insta{
    width:50%;
    height: 500px;
    /* background: green; */
    cursor: pointer;
}

.youtube{
    width:50%;
    height: 500px;
    /* background: red; */
    cursor: pointer;
}

@media only screen and (max-width: 800px){
    .standard-img{
    display: none;
    }
    .mobile-show{
        display: block;
    }
}


</style>