import { createStore } from 'vuex'

export default createStore({
  state: {
    // burgerVisible:false,
    burgerClose:true,
  },
  mutations: {
    BURGER_CLOSE_CHANGE(state,burgerClose){
      state.burgerClose=burgerClose
    },
  },
  actions: {
    burgerCloseChange(store,burgerClose){
      store.commit('BURGER_CLOSE_CHANGE', burgerClose)
    },
  },
  modules: {
  }
})
