<template>
  <div class="home">
    <Header v-show="burgerClose"/>
    <Navigation />
    <MobileMenu v-show="!burgerClose"/>
    <FimitSlider/>
    <!-- <Slider v-show="burgerClose"/>
    <AboutUs v-show="burgerClose"/>
    <SchoolPlan v-show="burgerClose"/>
    <Profesors v-show="burgerClose"/>
    <Baner v-show="burgerClose"/>
    <Contact v-show="burgerClose"/>
    <NewsLetter v-show="burgerClose"/>
    <Footer v-show="burgerClose"/>
    <AllRightsReserved v-show="burgerClose"/>
    <PopUp /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Navigation from '@/components/Navigation.vue';
import MobileMenu from '@/components/MobileMenu.vue';
// import Slider from '@/components/Slider.vue';
// import AboutUs from '@/components/AboutUs.vue';
// import SchoolPlan from '@/components/SchoolPlan.vue';
// import Profesors from '@/components/Profesors.vue';
// import Baner from '@/components/Baner.vue';
// import Contact from '@/components/Contact.vue';
// import NewsLetter from '@/components/NewsLetter.vue';
// import Footer from '@/components/Footer.vue';
// import AllRightsReserved from '@/components/AllRightsReserved.vue';
// import PopUp from '@/components/Popup.vue';
import FimitSlider from '@/components/FimitSlider.vue'
import { mapActions,mapState} from 'vuex';

export default {
  name: 'Home',
  components: {
    Header,
    Navigation,
    MobileMenu,
    // Slider,
    // AboutUs,
    // SchoolPlan,
    // Profesors,
    // Baner,
    // Contact,
    // NewsLetter,
    // Footer,
    // AllRightsReserved,
    // PopUp
    FimitSlider
  },
     computed:{
    ...mapState(['burgerClose'])
  },
  methods:{
    ...mapActions(['burgerVisibleChange']),
  }
}
</script>

<style scoped>


</style>